import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { preset } from 'vue-cli-plugin-vuetify-preset-rally/preset'
import colors from '@colors'
// import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

const options = {
  theme: {
    dark: false,
    themes: {
      light: {
        shaza_yellow: colors.shaza_yellow,
        shaza_dark_brown: colors.shaza_dark_brown,
        shaza_light_brown: colors.shaza_light_brown,
        shaza_dark_blue: colors.shaza_dark_blue,
        shaza_light_blue: colors.shaza_light_blue
      },
      dark: {
        shaza_yellow: colors.shaza_yellow,
        shaza_dark_brown: colors.shaza_dark_brown,
        shaza_light_brown: colors.shaza_light_brown,
        shaza_dark_blue: colors.shaza_dark_blue,
        shaza_light_blue: colors.shaza_light_blue
      }
    },
  },
}

export default new Vuetify({
  preset,
  ...options,
})