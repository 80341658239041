import i18n from './locales'
const lang = i18n.t('inputs');

console.log('i18n', i18n);
export default {
  episode(required){
    return [
      v => required? !!v || lang.episode.errors.required : true
    ]
  },
  chapter(required){
    return [
      v => required? !!v || lang.chapter.errors.required : true
    ]
  },
  subTitleBook(required){
    return [
      v => required? !!v || lang.subTitleBook.errors.required : true
    ]
  },
  subTitleSeries(required){
    return [
      v => required? !!v || lang.subTitleSeries.errors.required : true
    ]
  },
  season(required){
    return [
      v => required? !!v || lang.season.errors.required : true
    ]
  },
  title(required) {
    return [
      v => required? !!v || lang.title.errors.required : true
    ]
  },
  titleYear(required) {
    return [
      v => required? !!v || lang.titleYear.errors.required : true
    ]
  },
  titleType(required) {
    return [
      v => required? !!v || lang.titleType.errors.required : true
    ]
  },
  rated(required) {
    return [
      v => required? !!v || lang.rated.errors.required : true
    ]
  },
  poster(required, mimeTypes){
    return [
      v => required? !!v || lang.poster.errors.required : true,
      v => !v? true: mimeTypes.includes(v? v.type: 'none') || lang.poster.errors.incorrectMimeType,
    ]
  },
  audio(required, mimeTypes){
    return [
      v => required? !!v || lang.audio.errors.required : true,
      v => !v? true: mimeTypes.includes(v? v.type: 'none') || lang.audio.errors.incorrectMimeType,
    ]
  },
  previewUrl(required) {
    const urlRegex = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)
    return [
      v => required? !!v || lang.previewUrl.errors.required : true,
      v => !v? true : !!urlRegex.test(v) || lang.previewUrl.errors.validUrl
    ]
  },
  released(required) {
    return [
      v => required? !!v || lang.released.errors.required : true
    ]
  },
  score(required) {
    return [
      v => required? !!v || v === 0 || lang.score.errors.required : true
    ]
  },
  synopsis(required) {
    return [
      v => required? !!v || lang.synopsis.errors.required : true
    ]
  },
  runtimeMinutes(required) {
    return [
      v => required? v.length !==0 || lang.runtimeMinutes.errors.required : true
    ]
  },
  writers(required) {
    return [
      v => required? v.length !==0 || lang.writers.errors.required : true
    ]
  },
  directors(required) {
    return [
      v => required? v.length !==0 || lang.directors.errors.required : true
    ]
  },
  categories(required) {
    return [
      v => required? v.length !==0 || lang.categories.errors.required : true
    ]
  },
  genre(required) {
    return [
      v => required? v.length !==0 || lang.genre.errors.required : true
    ]
  },
  actors(required) {
    return [
      v => required? v.length !==0 || lang.actors.errors.required : true
    ]
  },
  trackName(required){
    return [
      v => required? !!v || lang.trackName.errors.required : true
    ]
  },
  trackNarrator(required){
    return [
      v => required? !!v || lang.trackNarrator.errors.required : true
    ]
  },
  trackNarratedLang(required){
    return [
      v => required? !!v || lang.trackNarratedLang.errors.required : true
    ]
  },
  trackPublicEnabled(required){
    return [
      v => required? !!v || lang.trackPublicEnabled.errors.required : true
    ]
  },
  trackAudio(required, mimeTypes){
    return [
      v => required? !!v || lang.trackAudio.errors.required : true,
      v => mimeTypes.includes(v? v.type: 'none') || lang.trackAudio.errors.incorrectMimeType,
    ]
  },
  trackReleaseDate(required){
    return [
      v => required? !!v || lang.trackAudio.errors.required : true
    ]
  },
}