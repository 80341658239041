import Vue from 'vue'
import VueRouter from 'vue-router'
import VueRouterBackButton from 'vue-router-back-button'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('../pages/Dashboard.vue'),
    meta: {
      icon: 'mdi-view-dashboard-outline',
      authRequired: true,
      listOrder: 0,
      showInNav: true
    }
  },
  {
    path: '/Auth',
    name: 'Auth',
    component: () => import('../pages/Auth.vue'),
    meta: {
      icon: 'mdi-view-dashboard-outline',
      authRequired: false,
      listOrder: 0,
      showInNav: false
    }
  },
  {
    path: '/titleManagement',
    name: 'TrackUploader',
    component: () => import('../pages/titleManagement.vue'),
    meta: {
      icon: 'mdi-file-upload-outline',
      authRequired: true,
      listOrder: 1,
      showInNav: true
    }
  },
  {
    path: '/TrackEditor/:editorState/:titleId?',
    name: 'TrackEditor',
    component: () => import('../components/titleManagement/titleEditor.vue'),
    meta: {
      icon: 'mdi-file-upload-outline',
      authRequired: true,
      listOrder: 1,
      showInNav: false
    }
  },
  {
    path: '/UserCommunication',
    name: 'UserCommunication',
    component: () => import('../pages/UserCommunication.vue'),
    meta: {
      icon: 'mdi-forum-outline',
      authRequired: true,
      listOrder: 2,
      showInNav: true
    }
  },
  {
    path: '/OperatorConfig',
    name: 'OperatorConfig',
    component: () => import('../pages/OperatorConfig.vue'),
    meta: {
      icon: 'mdi-account-group-outline',
      authRequired: true,
      listOrder: 3,
      showInNav: true
    }
  },
  {
    path: '/UsersOverview',
    name: 'UsersOverview',
    component: () => import('../pages/UsersOverview.vue'),
    meta: {
      icon: 'mdi-account-multiple-outline',
      authRequired: true,
      listOrder: 4,
      showInNav: true
    }
  },
  {
    path: '/UsersFeedback',
    name: 'UsersFeedback',
    component: () => import('../pages/UsersFeedback.vue'),
    meta: {
      icon: 'mdi-reply-all-outline',
      authRequired: true,
      listOrder: 5,
      showInNav: true
    }
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

Vue.use(VueRouterBackButton, { router })

router.beforeResolve((to, from, next) => {
  console.info(`Checking authed... `, to)
  if (to.matched.some(record => record.meta.authRequired)) {
    let user;
    if (Vue && Vue.prototype && Vue.prototype.$Amplify && Vue.prototype.$Amplify.Auth) {

    Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then(data => {
      if (data && data.signInUserSession) {
        user = data;
      }
      next()
    }).catch((e) => {
      next({
        path: '/Auth'
      });
    });
  }
  else {
    console.error(`Vue prototype $Amplify is not set..!!!!`)
    console.error(`$amplify: `, Vue && Vue.prototype && Vue.prototype.$Amplify || false)
    console.error(`$amplify: `, Vue && Vue.prototype && Vue.prototype.$Amplify || false)
  }
}
  next()
})

export default router
