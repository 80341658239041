export default {
  movieTitles: [
    { 
      id: 1,
      Title: 'Movie 1',
      Year: '2020',
      type: 'movie',
      SoundTrack: '',
      Poster: 'https://images.immediate.co.uk/production/volatile/sites/4/2018/07/GettyImages-490922474-5266b60.jpg?quality=90&resize=940%2C400',
      Categories: ['action', 'popular']
    },
    { 
      id: 2,
      Title: 'Movie 2',
      Year: '2019',
      type: 'movie',
      SoundTrack: '',
      Poster: 'https://image.freepik.com/free-vector/illustration-language-concept_53876-20610.jpg',
      Categories: ['drama', 'featured']
    },
    { 
      id: 3,
      Title: 'Tiny Pretty Things',
      Year: '2020',
      type: 'series',
      SoundTrack: '',
      Poster: 'https://m.media-amazon.com/images/M/MV5BMDZkMzEzOTEtZjc2Mi00ZDZlLWEwMTYtYjczMjM4NTI3MGM4XkEyXkFqcGdeQXVyODk4OTc3MTY@._V1_UY268_CR4,0,182,268_AL_.jpg',
      Categories: [ 'Drama', 'Mystery', 'Thriller']
    },
  ],
  adTrackData: [
    {
      titleId: 1,
      tracks: [
        {
          trackId: 15,
          fileUrl: 'https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_2MG.mp3',
          fileBlob: '',
          trackName: 'Movie 1 Engilsh Track',
          language: 'en',
          narrator: 'Jimmy Doe',
          releaseDate: '10/15/2005'
        },
        {
          trackId: 14,
          fileUrl: 'https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_2MG.mp3',
          fileBlob: '',
          trackName: 'Movie 1 Engilsh Track',
          language: 'af',
          narrator: 'Pieter Botha',
          releaseDate: '10/15/2005'
        }
      ]
    },
    {
      titleId: 2,
      tracks: [
        {
          trackId: 16,
          fileUrl: 'https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_2MG.mp3',
          fileBlob: '',
          trackName: 'Movie 2 Engilsh Track',
          language: 'en',
          narrator: 'Jimmy Doe',
          releaseDate: '10/15/2005'
        },
        {
          trackId: 17,
          fileUrl: 'https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_2MG.mp3',
          fileBlob: '',
          trackName: 'Movie 2 Engilsh Track',
          language: 'af',
          narrator: 'Pieter Botha',
          releaseDate: '10/15/2005'
        }
      ]
    },
    {
      titleId: 3,
      tracks: [
        {
          trackId: 18,
          fileUrl: 'https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_2MG.mp3',
          fileBlob: '',
          trackName: 'Tiny Pretty Things Engilsh Track',
          language: 'en',
          narrator: 'Jimmy Doe',
          releaseDate: '10/15/2005'
        },
        {
          trackId: 19,
          fileUrl: 'https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_2MG.mp3',
          fileBlob: '',
          trackName: 'Tiny Pretty Things Engilsh Track',
          language: 'af',
          narrator: 'Pieter Botha',
          releaseDate: '10/15/2005'
        }
      ]
    }
  ] 
}