/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:8c41d40c-e904-4858-b26c-6fcd3dadc5d0",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_1NCKUAaRv",
    "aws_user_pools_web_client_id": "7lkkiglmfdo22ptsii92j0df96",
    "oauth": {
        "domain": "shazacinadminportal1c4973ff-1c4973ff-dev.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:8080/,https://admin.shazacin.com/", 
        "redirectSignOut": "http://localhost:8080/,https://admin.shazacin.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://dns5yl2h7vdolk47n6l7rouaky.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-yz5jeahypzgmldn6bvhv4ioqxq"
};


export default awsmobile;
