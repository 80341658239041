/* eslint-disable no-console */

import { register } from 'register-service-worker'
let newContent = false
if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      newContent = true
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.')
      if (newContent) {
        setTimeout(() => {
          console.info('Trying an auto-refresh: ')
          window.location.reload()
        }, 2000)
      }
    },
    fetch(event) {
      console.log('Fetch happened. regSW.js')
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
