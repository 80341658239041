export default {
  appMeta: {
    appName: 'Shazacin Admin'
  },
  pageMeta: {
    Dashboard: {
      displayName: 'Dashboard',
      descriotion: 'Dashboard'
    },
    TrackUploader: {
      displayName: 'Title Management',
      descriotion: 'Track Uploader'
    },
    TrackEditor: {
      displayName: 'Title Editor',
      descriotion: 'Title Editor'
    },
    UserCommunication: {
      displayName: 'User Communication',
      descriotion: 'User Communication'
    },
    OperatorConfig: {
      displayName: 'Operator Configuration',
      descriotion: 'Operator Configuration'
    },
    UsersOverview: {
      displayName: 'App Users Overview',
      descriotion: 'App Users Overview'
    },
    UsersFeedback: {
      displayName: 'App Users Feedback',
      descriotion: 'App Users Feedback'
    }
  },
  notifs: {
    userCommunication: {
      createShazacinUserNotifications: {
        busy: 'Publishing notification to {users}...',
        error: 'Could not publish notification :: {err}',
        success: 'Notification successfully published'
      },
    },
    titleEditor: {
      delete: {
        busy: 'Deleting {title}...',
        error: 'Could not delete {title} :: {err}',
        success: '{title} deleted'
      },
      update: {
        busy: 'Updating {title}...',
        error: 'Could not update {title} :: {err}',
        success: '{title} updated'
      },
      create: {
        busy: 'Creating {title}...',
        error: 'Could not create {title} :: {err}',
        success: '{title} created'
      },
      uploadAudio: {
        busy: 'Uploading audio file for {title}...',
        error: 'Could not upload audio file for {title} :: {err}',
        success: '{title} audio file uploaded'
      },
      uploadPoster: {
        busy: 'Uploading poster for {title}...',
        error: 'Could not upload poster for {title} :: {err}',
        success: '{title} poster uploaded'
      }
    },
    trackEditor: {
      delete: {
        busy: 'Deleting {track}...',
        error: 'Could not delete {track} :: {err}',
        success: '{track} deleted'
      },
      update: {
        busy: 'Updating {track}...',
        error: 'Could not update {track} :: {err}',
        success: '{track} updated'
      },
      create: {
        busy: 'Creating {track}...',
        error: 'Could not create {track} :: {err}',
        success: '{track} created'
      },
      uploadAudio: {
        busy: 'Uploading audio file for {track}...',
        error: 'Could not upload audio file for {track} :: {err}',
        success: '{track} audio file uploaded'
      }
    }
  },
  buttons: {
    signIn: {
      label: 'Sign In',
      tooltip: 'Sign In'
    },
    logout: {
      label: 'Logout',
      tooltip: 'Logout'
    },
    delete: {
      label: 'Delete',
      tooltip: 'Delete'
    },
    cancel: {
      label: 'Cancel',
      tooltip: 'Cancel'
    },
    back: {
      label: 'Go Back',
      tooltip: 'Go Back'
    },
    searchLabel: {
      label: 'Search Title',
      tooltip: 'Search web for this titles information.'
    },
    addNewTitle: {
      label: 'Add Title',
      tooltip: 'Add Title'
    },
    createTitle: {
      label: 'create Title',
      tooltip: 'create Title'
    },
    updateTitle: {
      label: 'Update Title',
      tooltip: 'Update Title'
    },
    deleteTitle: {
      label: 'Delete',
      tooltip: 'Delete'
    },
    saveAdTracks: {
      label: 'Create Track',
      tooltip: 'Create Track'
    },
    updateAdTracks: {
      label: 'Update Track',
      tooltip: 'Update Track'
    },
    addAdTrack: {
      label: 'Add Track',
      tooltip: 'Add Track'
    },
    deleteAdTrack: {
      label: 'Delete',
      tooltip: 'Delete'
    },
    updateTitle: {
      label: 'Update Title',
      tooltip: 'Save all changes to this Title'
    }
  },
  inputs: {
    search: {
      label: 'Search',
    },
    poster: {
      label: 'Title Poster',
      errors: {
        required: 'A title Poster is required',
        incorrectMimeType: 'Incorrect file type'
      }
    },
    audio: {
      label: 'Original Title Audio Track',
      errors: {
        required: 'A title Audio track is required',
        incorrectMimeType: 'Incorrect file type'
      }
    },
    TitlePublicEnabled: {
      label: 'Enabled'
    },
    titleSearch: {
      label: 'Search Title'
    },
    episode: {
      label: 'Episode',
      errors: {
        required: 'An Episode number is required'
      }
    },
    subTitleBook: {
      label: 'Book',
      errors: {
        required: 'A Book is required'
      }
    },
    subTitleSeries: {
      label: 'Series',
      errors: {
        required: 'An existing Series is required'
      }
    },
    season: {
      label: 'Season',
      errors: {
        required: 'A Season number is required'
      }
    },
    chapter: {
      label: 'Chapter',
      errors: {
        required: 'A Chapter number is required'
      }
    },
    chapterTitle: {
      label: 'Chapter title',
      errors: {
        required: 'A chapter title is required'
      }
    },
    title: {
      label: 'Title Name',
      errors: {
        required: 'A title Name is required'
      }
    },
    titleYear: {
      label: 'Title Year',
      errors: {
        required: 'A title Year is required'
      }
    },
    titleType: {
      label: 'Title Type',
      errors: {
        required: 'A title Type is required'
      }
    },
    directors: {
      label: 'Title Director',
      errors: {
        required: 'At least one Director is required'
      }
    },
    rated: {
      label: 'Title Rating',
      errors: {
        required: 'A Rating is required'
      }
    },
    score: {
      label: 'Title Score',
      errors: {
        required: 'A Score is required'
      }
    },
    synopsis: {
      label: 'Title Synopsis',
      errors: {
        required: 'A Synopsis is required'
      }
    },
    previewUrl: {
      label: 'Preview URL (e.g. Youtube Embed link to film preview)',
      errors: {
        required: 'A title Preview URL is required',
        validUrl: 'Please enter a valid URL'
      }
    },
    wheretowatch: {
      label: "Where to watch? (e.g. Netflix)"
    },
    released: {
      label: 'Release Date',
      errors: {
        required: 'A Release Date is required'
      }
    },
    runtimeMinutes: {
      label: 'Title Run Time (Minutes)',
      errors: {
        required: 'A title Run Time is required'
      }
    },
    writers: {
      label: 'Title Writers',
      errors: {
        required: 'At least one Writer is required'
      }
    },
    bookWriters: {
      label: 'Title Author',
      errors: {
        required: 'At least one Author is required'
      }
    },
    categories: {
      label: 'Title Categories',
      errors: {
        required: 'At least one Category is required'
      }
    },
    genre: {
      label: 'Title Genres',
      errors: {
        required: 'At least one Genre is required'
      }
    },
    magic_adjust: {
      label: 'Magic Adjust (seconds + or -)',
      errors: {
      }
    },
    actors: {
      label: 'Title Actors',
      errors: {
        required: 'At least one Actor is required'
      }
    },
    chapterActors: {
      label: 'Description voicer',
      errors: {
        required: 'At least one description voicer is required'
      }
    },
    bookActors: {
      label: 'Description voicer',
      errors: {
        required: 'At least one description voicer is required'
      }
    },
    trackName: {
      label: 'Track Name',
      errors: {
        required: 'A track name is required'
      }
    },
    trackPosition: {
      label: 'Track Position'
    },
    trackNarrator: {
      label: 'Track Narrator',
      errors: {
        required: 'A narrator is required'
      }
    },
    trackNarratedLang: {
      label: 'Narrated language',
      errors: {
        required: 'At narrated language is required'
      }
    },
    trackPublicEnabled: {
      label: 'Publicly Enabled',
      errors: {

      }
    },
    trackAudio: {
      label: 'AD Track',
      errors: {
        required: 'An AD track is required',
        incorrectMimeType: 'Incorrect file type'
      }
    },
    trackReleaseDate: {
      label: 'Track Release Date',
      errors: {
        required: 'At least one Actor is required'
      }
    }
  },
  pages: {
    TrackUploader: {
      titleTable: {
        noData: 'No Tracks to view',
        headers: {
          actors: 'Actors',
          categories: 'Categories',
          createdAt: 'Date Created',
          director: 'Director',
          fingerprinting_progress: 'Fingerprinting progress',
          genre: 'Genre',
          images: 'Poster',
          previewUrl: 'Preview Url',
          publicEnabled: 'Public Enabled',
          rated: 'Rated',
          released: 'Released',
          runtimeMinutes: 'Runtime Minutes',
          score: 'Score',
          synopsis: 'Synopsis',
          title: 'Title',
          type: 'Type',
          updatedAt: 'Last Updated',
          writer: 'Writer',
          year: 'Year',
        }
      },
      trackCard: {
        heading: `AD Track`
      },
      titleTypes: {
        movie: 'Movie',
        series: 'Series',
        episode: 'Series Episode',
        book: 'Book',
        chapter: 'Book Chapter',
        tour: 'Tour',
      }
    }
  }
}