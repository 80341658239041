import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import uiMod from './modules/uiComponents'

const store = new Vuex.Store({
  modules: {
    uiMod
  }
})

export default store