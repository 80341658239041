import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './sass/main.style'
import VueMeta from 'vue-meta' 
import i18n from './locales'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import './mixins'


Vue.config.productionTip = false

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(VueLodash, { name: 'LD' , lodash: lodash })

//AMPLIFY
import Amplify, { graphqlOperation, API, Auth, Storage } from 'aws-amplify'
import aws_exports from './aws-exports';

Vue.prototype.$Amplify = Amplify
Vue.prototype.$Amplify.Auth = Auth
Vue.prototype.$Amplify.API = API
Vue.prototype.$Amplify.graphqlOperation = graphqlOperation
Vue.prototype.$Amplify.Storage = Storage

//CONFIGURE
const oAuthDomain = 'adminauth.shazacin.com'
let urlsIn = aws_exports.oauth.redirectSignIn.split(",");
let urlsOut = aws_exports.oauth.redirectSignOut.split(",");

const oauth = {
  domain: oAuthDomain || aws_exports.oauth.domain,
  scope: aws_exports.oauth.scope,
  redirectSignIn: aws_exports.oauth.redirectSignIn,
  redirectSignOut: aws_exports.oauth.redirectSignOut,
  responseType: aws_exports.oauth.responseType
};

let hasLocalhost = (hostname) => Boolean(hostname.match(/localhost/) || hostname.match(/127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/));
let hasHostname = (hostname) => Boolean(hostname.includes(window.location.hostname));
let isLocalhost = hasLocalhost(window.location.hostname);
if (isLocalhost) {
  urlsIn.forEach((e) => { if (hasLocalhost(e)) { oauth.redirectSignIn = e; } });
  urlsOut.forEach((e) => { if (hasLocalhost(e)) { oauth.redirectSignOut = e; } });
}
else {
  urlsIn.forEach((e) => { if (hasHostname(e)) { oauth.redirectSignIn = e; } });
  urlsOut.forEach((e) => { if (hasHostname(e)) { oauth.redirectSignOut = e; } });
}
let configUpdate = aws_exports;
configUpdate.oauth = oauth;
Amplify.configure(configUpdate);

Vue.prototype.$Amplify = Amplify
Vue.prototype.$Amplify.Auth = Auth
console.log('STORE', store);

import '@/components/uiComponents'

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
