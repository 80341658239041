import vue from 'vue'
console.log('VUE', vue);

const all = {
  brandmark_white: require('@/assets/images/brand/brandmark_white.svg'),
  brandmark_black: require('@/assets/images/brand/brandmark_black.svg'),
  logo_white_wordmark_plain: require('@/assets/images/brand/logo_white_wordmark_plain.svg'),
  logo_black_wordmark_plain: require('@/assets/images/brand/logo_black_wordmark_plain.svg'),
}

const dark = {
  brandmark: all.brandmark_white,
  logo_wordmark_plain: all.logo_dark_wordmark_plain,
}
const light = {
  brandmark: all.brandmark_black,
  logo_wordmark_plain: all.logo_black_wordmark_plain,
}


export default { all, dark, light }