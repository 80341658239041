const mutationTypes = {
  SET_ALERT_CFG: 'SET_ALERT_CFG',
  SET_ALERT_VISIBILITY: 'SET_ALERT_VISIBILITY',
  SET_CONFIRM_MODAL_CFG: 'SET_CONFIRM_MODAL_CFG',
  SET_CONFIRM_MODAL_VISIBILITY: 'SET_CONFIRM_MODAL_VISIBILITY',
  SET_BUFFER_OVERLAY_CFG: 'SET_BUFFER_OVERLAY_CFG',
  SET_BUFFER_OVERLAY_VISIBILITY: 'SET_BUFFER_OVERLAY_VISIBILITY'
}

const initalState = () => {
  return{
    bufferOverlayCfg: {},
    bufferOverlayVisibility: false,
    alertCfg: {},
    alertVisibility: false,
    confirmModalCfg: {},
    confirmModalVisibility: false
  }
}
const pause = (ms) => new Promise(resolve => setTimeout(resolve, ms ))
export default {
  namespaced: true,
  state: initalState(),
  getters: {
    getAlertCfg: state => {
      return state.alertCfg
    },
    getAlertVisibility: state =>{
      return state.alertVisibility
    },
    getConfirmModalCfg: state => {
      return state.confirmModalCfg
    },
    getConfirmModalVisibility: state =>{
      return state.confirmModalVisibility
    },
    getBufferOverlayCfg: state => {
      return state.bufferOverlayCfg
    },
    getBufferOverlayVisibility: state =>{
      return state.bufferOverlayVisibility
    }
  },
  mutations: {
    [mutationTypes.SET_ALERT_CFG](state, cfg){
      console.log('SETTING ALERT CFG', cfg);
      state.alertCfg = cfg;
    },
    [mutationTypes.SET_ALERT_VISIBILITY](state, visibility){
      console.log('SETTING ALERT VISIBLE', visibility);
      state.alertVisibility = visibility;
    },
    [mutationTypes.SET_CONFIRM_MODAL_CFG](state, cfg){
      console.log('SETTING MODAL CFG', cfg);
      state.confirmModalCfg = cfg;
    },
    [mutationTypes.SET_CONFIRM_MODAL_VISIBILITY](state, visibility){
      console.log('SETTING MODAL VISIBLE', visibility);
      state.confirmModalVisibility = visibility;
    },
    [mutationTypes.SET_BUFFER_OVERLAY_CFG](state, cfg){
      console.log('SETTING BUFFER OVERLAY CFG', cfg);
      state.bufferOverlayCfg = cfg;
    },
    [mutationTypes.SET_BUFFER_OVERLAY_VISIBILITY](state, visibility){
      console.log('SETTING BUFFER OVERLAY VISIBLE', visibility);
      state.bufferOverlayVisibility = visibility;
    },
  },
  actions: {
    setAlertVisibility({dispatch, commit}, visibility){
      
      if(!visibility){
        commit(mutationTypes.SET_ALERT_CFG, {});
      }
      commit(mutationTypes.SET_ALERT_VISIBILITY, visibility);
    },
    async setAlertCfg({dispatch, commit}, cfg){
      commit(mutationTypes.SET_ALERT_CFG, {});
      commit(mutationTypes.SET_ALERT_CFG, cfg);
      dispatch('setAlertVisibility', true);
      await pause(cfg.timeout || 5000);
      dispatch('setAlertVisibility', false);
    },
    setConfirmModalVisibility({dispatch, commit}, visibility){
      
      if(!visibility){
        commit(mutationTypes.SET_CONFIRM_MODAL_CFG, {});
      }
      commit(mutationTypes.SET_CONFIRM_MODAL_VISIBILITY, visibility);
    },
    setConfirmModalCfg({dispatch, commit}, cfg){
      commit(mutationTypes.SET_CONFIRM_MODAL_CFG, {});
      commit(mutationTypes.SET_CONFIRM_MODAL_CFG, cfg);
      dispatch('setConfirmModalVisibility', true);
    },
    setBufferOverlayVisibility({dispatch, commit}, visibility){
      
      if(!visibility){
        commit(mutationTypes.SET_BUFFER_OVERLAY_CFG, {});
      }
      commit(mutationTypes.SET_BUFFER_OVERLAY_VISIBILITY, visibility);
    },
    setBufferOverlayCfg({dispatch, commit}, cfg){
      commit(mutationTypes.SET_BUFFER_OVERLAY_CFG, {});
      commit(mutationTypes.SET_BUFFER_OVERLAY_CFG, cfg);
      dispatch('setBufferOverlayVisibility', true);
    }
  },
}