export default {
    /**  AVAILABLE INPUTS 
        publicEnabled
        poster
        audio
        subTitleBook
        subTitleSeries
        episode
        season
        chapter
        title
        directors
        writers
        actors
        runtimeMinutes
        released
        rated
        previewUrl
        score
        categories
        genre
        synopsis   
    */
    titleTypeUiConfig:[
        {
            type: 'movie',
            tabIndex: 0,
            inputCfg: [
                { input: 'type', required: true },
                { input: 'poster', required: false },
                { input: 'audio', required: false },
                // { input: 'subTitleBook',  required: false },
                // { input: 'subTitleSeries', required: false },
                // { input: 'episode', required: false },
                // { input: 'season', required: false },
                // { input: 'chapter', required: false },
                { input: 'title', required: true},
                { input: 'directors', required: true },
                { input: 'writers', required: true },
                { input: 'actors', required: true },
                { input: 'runtimeMinutes', required: true },
                { input: 'released', required: true },
                { input: 'previewUrl', required: false },
                { input: 'wheretowatch', required: false },
                { input: 'score', required: true },
                { input: 'categories', required: true },
                { input: 'genre', required: true },
                { input: 'synopsis', required: true },
                { input: 'publicEnabled', required: true },
                { input: 'year', required: false },
                { input: 'rated', required: true }
            ],
            showAdTracks: true,
            showIMDB: true
        },
        {   
            type: 'series',
            tabIndex: 1,
            inputCfg: [
                { input: 'type', required: true },
                { input: 'poster', required: false },
                // { input: 'audio', required: false },
                // { input: 'subTitleBook',  required: false },
                // { input: 'subTitleSeries', required: false },
                // { input: 'episode', required: false },
                // { input: 'season', required: false },
                // { input: 'chapter', required: false },
                { input: 'title', required: true},
                { input: 'directors', required: true },
                { input: 'writers', required: true },
                { input: 'actors', required: true },
                // { input: 'runtimeMinutes', required: false },
                { input: 'released', required: true },
                { input: 'previewUrl', required: false },
                { input: 'wheretowatch', required: false },
                { input: 'score', required: true },
                { input: 'categories', required: true },
                { input: 'genre', required: true },
                { input: 'synopsis', required: true },
                { input: 'publicEnabled', required: true },
                { input: 'year', required: false },
                { input: 'rated', required: true }
            ],
            showAdTracks: false,
            showIMDB: true
        },
        {   
            type: 'episode',
            tabIndex: 2,
            inputCfg: [
                { input: 'type', required: true },
                { input: 'poster', required: false },
                { input: 'audio', required: false },
                // { input: 'subTitleBook',  required: false },
                { input: 'subTitleSeries', required: true },
                { input: 'episode', required: true },
                { input: 'season', required: true },
                // { input: 'chapter', required: false },
                { input: 'title', required: true},
                { input: 'directors', required: false },
                { input: 'writers', required: false },
                { input: 'actors', required: false },
                { input: 'runtimeMinutes', required: false },
                { input: 'released', required: false },
                { input: 'previewUrl', required: false },
                { input: 'score', required: true },
                { input: 'categories', required: false },
                { input: 'genre', required: false },
                { input: 'synopsis', required: false },
                { input: 'publicEnabled', required: false },
                { input: 'year', required: false },
                { input: 'rated', required: true }
            ],
            showAdTracks: true,
            showIMDB: true
        },
        {   
            type: 'book',
            tabIndex: 3,
            inputCfg: [
                { input: 'type', required: true },
                { input: 'poster', required: false },
                // { input: 'audio', required: false },
                // { input: 'subTitleBook',  required: false },
                // { input: 'subTitleSeries', required: false },
                // { input: 'episode', required: false },
                // { input: 'season', required: false },
                // { input: 'chapter', required: false },
                { input: 'title', required: true},
                // { input: 'directors', required: false },
                { input: 'writers', required: true },
                { input: 'actors', required: false },
                { input: 'runtimeMinutes', required: false },
                { input: 'released', required: true },
                // { input: 'previewUrl', required: false },
                { input: 'score', required: true },
                { input: 'categories', required: true },
                { input: 'genre', required: true },
                { input: 'synopsis', required: true },
                { input: 'publicEnabled', required: false },
                { input: 'year', required: false },
                { input: 'rated', required: true }
            ],
            showAdTracks: false,
            showIMDB: false
        },
        {
            type: 'chapter',
            tabIndex: 4,
            inputCfg: [
                { input: 'type', required: true },
                { input: 'poster', required: false },
                // { input: 'audio', required: false },
                { input: 'subTitleBook',  required: true },
                // { input: 'subTitleSeries', required: false },
                // { input: 'episode', required: false },
                // { input: 'season', required: false },
                { input: 'chapter', required: true },
                { input: 'title', required: true},
                // { input: 'directors', required: false },
                // { input: 'writers', required: true },
                { input: 'actors', required: false },
                { input: 'runtimeMinutes', required: true },
                { input: 'released', required: true },
                // { input: 'previewUrl', required: false },
                { input: 'score', required: true },
                { input: 'categories', required: true },
                { input: 'genre', required: true },
                // { input: 'synopsis', required: true },
                { input: 'publicEnabled', required: false },
                { input: 'year', required: false },
                { input: 'rated', required: true }
            ],
            showAdTracks: true,
            showIMDB: false
        },
        {
            type: 'tour',
            tabIndex: 5,
            inputCfg: [
                { input: 'type', required: true },
                { input: 'poster', required: false },
                // { input: 'audio', required: false },
                // { input: 'subTitleBook',  required: true },
                // { input: 'subTitleSeries', required: false },
                // { input: 'episode', required: false },
                // { input: 'season', required: false },
                // { input: 'chapter', required: true },
                { input: 'title', required: true},
                // { input: 'directors', required: false },
                { input: 'writers', required: true },
                // { input: 'actors', required: false },
                { input: 'runtimeMinutes', required: true },    
                { input: 'released', required: true },
                // { input: 'previewUrl', required: false },
                // { input: 'wheretowatch', required: false },
                { input: 'score', required: true },
                { input: 'categories', required: true },
                { input: 'genre', required: true },
                { input: 'synopsis', required: true },
                { input: 'publicEnabled', required: false },
                { input: 'year', required: false },
                { input: 'rated', required: true }
            ],
            showAdTracks: true,
            showIMDB: false
        }
    ],
    mimeTypeExtensionMapping: {
        images: [
            {   
                mimeType: 'image/webp',
                extension: '.webp',
            },
            {   
                mimeType: 'image/jpeg',
                extension: '.jpg',
            },
            {   
                mimeType: 'image/avif',
                extension: '.avif',
            },
            {   
                mimeType: 'image/avif-sequence',
                extension: '.avifs',
            },
            {   
                mimeType: 'image/png',
                extension: '.png',
            }
        ],
        audio: [
            {   
                mimeType: 'audio/mpeg',
                extension: '.mp3',
            }
        ],
        adTrackAudio: [
            {   
                mimeType: 'audio/wav',
                extension: '.wav',
            }, 
            {   
                mimeType: 'audio/mpeg',
                extension: '.mp3',
            }, 
            {   
                mimeType: 'audio/ogg',
                extension: '.oga',
            },
        ]
    },
    languages: [
        {
            name: "Afar",
            code: "aa",
            a3_code: "aar"
        },
        {
            name: "Abkhazian",
            code: "ab",
            a3_code: "abk"
        },
        {
            name: "Afrikaans",
            code: "af",
            a3_code: "afr"
        },
        {
            name: "Akan",
            code: "ak",
            a3_code: "aka"
        },
        {
            name: "Albanian",
            code: "sq",
            a3_code: "alb"
        },
        {
            name: "Amharic",
            code: "am",
            a3_code: "amh"
        },
        {
            name: "Arabic",
            code: "ar",
            a3_code: "ara"
        },
        {
            name: "Aragonese",
            code: "an",
            a3_code: "arg"
        },
        {
            name: "Armenian",
            code: "hy",
            a3_code: "arm"
        },
        {
            name: "Assamese",
            code: "as",
            a3_code: "asm"
        },
        {
            name: "Avaric",
            code: "av",
            a3_code: "ava"
        },
        {
            name: "Avestan",
            code: "ae",
            a3_code: "ave"
        },
        {
            name: "Aymara",
            code: "ay",
            a3_code: "aym"
        },
        {
            name: "Azerbaijani",
            code: "az",
            a3_code: "aze"
        },
        {
            name: "Bashkir",
            code: "ba",
            a3_code: "bak"
        },
        {
            name: "Bambara",
            code: "bm",
            a3_code: "bam"
        },
        {
            name: "Basque",
            code: "eu",
            a3_code: "baq"
        },
        {
            name: "Belarusian",
            code: "be",
            a3_code: "bel"
        },
        {
            name: "Bengali",
            code: "bn",
            a3_code: "ben"
        },
        {
            name: "Bihari languages",
            code: "bh",
            a3_code: "bih"
        },
        {
            name: "Bislama",
            code: "bi",
            a3_code: "bis"
        },
        {
            name: "Bosnian",
            code: "bs",
            a3_code: "bos"
        },
        {
            name: "Breton",
            code: "br",
            a3_code: "bre"
        },
        {
            name: "Bulgarian",
            code: "bg",
            a3_code: "bul"
        },
        {
            name: "Burmese",
            code: "my",
            a3_code: "bur"
        },
        {
            name: "Catalan; Valencian",
            code: "ca",
            a3_code: "cat"
        },
        {
            name: "Chamorro",
            code: "ch",
            a3_code: "cha"
        },
        {
            name: "Chechen",
            code: "ce",
            a3_code: "che"
        },
        {
            name: "Chinese",
            code: "zh",
            a3_code: "chi"
        },
        {
            name: "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic",
            code: "cu",
            a3_code: "chu"
        },
        {
            name: "Chuvash",
            code: "cv",
            a3_code: "chv"
        },
        {
            name: "Cornish",
            code: "kw",
            a3_code: "cor"
        },
        {
            name: "Corsican",
            code: "co",
            a3_code: "cos"
        },
        {
            name: "Cree",
            code: "cr",
            a3_code: "cre"
        },
        {
            name: "Czech",
            code: "cs",
            a3_code: "cze"
        },
        {
            name: "Danish",
            code: "da",
            a3_code: "dan"
        },
        {
            name: "Divehi; Dhivehi; Maldivian",
            code: "dv",
            a3_code: "div"
        },
        {
            name: "Dutch; Flemish",
            code: "nl",
            a3_code: "dut"
        },
        {
            name: "Dzongkha",
            code: "dz",
            a3_code: "dzo"
        },
        {
            name: "English",
            code: "en",
            a3_code: "eng"
        },
        {
            name: "Esperanto",
            code: "eo",
            a3_code: "epo"
        },
        {
            name: "Estonian",
            code: "et",
            a3_code: "est"
        },
        {
            name: "Ewe",
            code: "ee",
            a3_code: "ewe"
        },
        {
            name: "Faroese",
            code: "fo",
            a3_code: "fao"
        },
        {
            name: "Fijian",
            code: "fj",
            a3_code: "fij"
        },
        {
            name: "Finnish",
            code: "fi",
            a3_code: "fin"
        },
        {
            name: "French",
            code: "fr",
            a3_code: "fre"
        },
        {
            name: "Western Frisian",
            code: "fy",
            a3_code: "fry"
        },
        {
            name: "Fulah",
            code: "ff",
            a3_code: "ful"
        },
        {
            name: "Georgian",
            code: "ka",
            a3_code: "geo"
        },
        {
            name: "German",
            code: "de",
            a3_code: "ger"
        },
        {
            name: "Gaelic; Scottish Gaelic",
            code: "gd",
            a3_code: "gla"
        },
        {
            name: "Irish",
            code: "ga",
            a3_code: "gle"
        },
        {
            name: "Galician",
            code: "gl",
            a3_code: "glg"
        },
        {
            name: "Manx",
            code: "gv",
            a3_code: "glv"
        },
        {
            name: "Greek, Modern (1453-)",
            code: "el",
            a3_code: "gre"
        },
        {
            name: "Guarani",
            code: "gn",
            a3_code: "grn"
        },
        {
            name: "Gujarati",
            code: "gu",
            a3_code: "guj"
        },
        {
            name: "Haitian; Haitian Creole",
            code: "ht",
            a3_code: "hat"
        },
        {
            name: "Hausa",
            code: "ha",
            a3_code: "hau"
        },
        {
            name: "Hebrew",
            code: "he",
            a3_code: "heb"
        },
        {
            name: "Herero",
            code: "hz",
            a3_code: "her"
        },
        {
            name: "Hindi",
            code: "hi",
            a3_code: "hin"
        },
        {
            name: "Hiri Motu",
            code: "ho",
            a3_code: "hmo"
        },
        {
            name: "Croatian",
            code: "hr",
            a3_code: "hrv"
        },
        {
            name: "Hungarian",
            code: "hu",
            a3_code: "hun"
        },
        {
            name: "Igbo",
            code: "ig",
            a3_code: "ibo"
        },
        {
            name: "Icelandic",
            code: "is",
            a3_code: "ice"
        },
        {
            name: "Ido",
            code: "io",
            a3_code: "ido"
        },
        {
            name: "Sichuan Yi; Nuosu",
            code: "ii",
            a3_code: "iii"
        },
        {
            name: "Inuktitut",
            code: "iu",
            a3_code: "iku"
        },
        {
            name: "Interlingue; Occidental",
            code: "ie",
            a3_code: "ile"
        },
        {
            name: "Interlingua (International Auxiliary Language Association)",
            code: "ia",
            a3_code: "ina"
        },
        {
            name: "Indonesian",
            code: "id",
            a3_code: "ind"
        },
        {
            name: "Inupiaq",
            code: "ik",
            a3_code: "ipk"
        },
        {
            name: "Italian",
            code: "it",
            a3_code: "ita"
        },
        {
            name: "Javanese",
            code: "jv",
            a3_code: "jav"
        },
        {
            name: "Japanese",
            code: "ja",
            a3_code: "jpn"
        },
        {
            name: "Kalaallisut; Greenlandic",
            code: "kl",
            a3_code: "kal"
        },
        {
            name: "Kannada",
            code: "kn",
            a3_code: "kan"
        },
        {
            name: "Kashmiri",
            code: "ks",
            a3_code: "kas"
        },
        {
            name: "Kanuri",
            code: "kr",
            a3_code: "kau"
        },
        {
            name: "Kazakh",
            code: "kk",
            a3_code: "kaz"
        },
        {
            name: "Central Khmer",
            code: "km",
            a3_code: "khm"
        },
        {
            name: "Kikuyu; Gikuyu",
            code: "ki",
            a3_code: "kik"
        },
        {
            name: "Kinyarwanda",
            code: "rw",
            a3_code: "kin"
        },
        {
            name: "Kirghiz; Kyrgyz",
            code: "ky",
            a3_code: "kir"
        },
        {
            name: "Komi",
            code: "kv",
            a3_code: "kom"
        },
        {
            name: "Kongo",
            code: "kg",
            a3_code: "kon"
        },
        {
            name: "Korean",
            code: "ko",
            a3_code: "kor"
        },
        {
            name: "Kuanyama; Kwanyama",
            code: "kj",
            a3_code: "kua"
        },
        {
            name: "Kurdish",
            code: "ku",
            a3_code: "kur"
        },
        {
            name: "Lao",
            code: "lo",
            a3_code: "lao"
        },
        {
            name: "Latin",
            code: "la",
            a3_code: "lat"
        },
        {
            name: "Latvian",
            code: "lv",
            a3_code: "lav"
        },
        {
            name: "Limburgan; Limburger; Limburgish",
            code: "li",
            a3_code: "lim"
        },
        {
            name: "Lingala",
            code: "ln",
            a3_code: "lin"
        },
        {
            name: "Lithuanian",
            code: "lt",
            a3_code: "lit"
        },
        {
            name: "Luxembourgish; Letzeburgesch",
            code: "lb",
            a3_code: "ltz"
        },
        {
            name: "Luba-Katanga",
            code: "lu",
            a3_code: "lub"
        },
        {
            name: "Ganda",
            code: "lg",
            a3_code: "lug"
        },
        {
            name: "Macedonian",
            code: "mk",
            a3_code: "mac"
        },
        {
            name: "Marshallese",
            code: "mh",
            a3_code: "mah"
        },
        {
            name: "Malayalam",
            code: "ml",
            a3_code: "mal"
        },
        {
            name: "Maori",
            code: "mi",
            a3_code: "mao"
        },
        {
            name: "Marathi",
            code: "mr",
            a3_code: "mar"
        },
        {
            name: "Malay",
            code: "ms",
            a3_code: "may"
        },
        {
            name: "Malagasy",
            code: "mg",
            a3_code: "mlg"
        },
        {
            name: "Maltese",
            code: "mt",
            a3_code: "mlt"
        },
        {
            name: "Mongolian",
            code: "mn",
            a3_code: "mon"
        },
        {
            name: "Nauru",
            code: "na",
            a3_code: "nau"
        },
        {
            name: "Navajo; Navaho",
            code: "nv",
            a3_code: "nav"
        },
        {
            name: "Ndebele, South; South Ndebele",
            code: "nr",
            a3_code: "nbl"
        },
        {
            name: "Ndebele, North; North Ndebele",
            code: "nd",
            a3_code: "nde"
        },
        {
            name: "Ndonga",
            code: "ng",
            a3_code: "ndo"
        },
        {
            name: "Nepali",
            code: "ne",
            a3_code: "nep"
        },
        {
            name: "Norwegian Nynorsk; Nynorsk, Norwegian",
            code: "nn",
            a3_code: "nno"
        },
        {
            name: "Bokmål, Norwegian; Norwegian Bokmål",
            code: "nb",
            a3_code: "nob"
        },
        {
            name: "Norwegian",
            code: "no",
            a3_code: "nor"
        },
        {
            name: "Chichewa; Chewa; Nyanja",
            code: "ny",
            a3_code: "nya"
        },
        {
            name: "Occitan (post 1500)",
            code: "oc",
            a3_code: "oci"
        },
        {
            name: "Ojibwa",
            code: "oj",
            a3_code: "oji"
        },
        {
            name: "Oriya",
            code: "or",
            a3_code: "ori"
        },
        {
            name: "Oromo",
            code: "om",
            a3_code: "orm"
        },
        {
            name: "Ossetian; Ossetic",
            code: "os",
            a3_code: "oss"
        },
        {
            name: "Panjabi; Punjabi",
            code: "pa",
            a3_code: "pan"
        },
        {
            name: "Persian",
            code: "fa",
            a3_code: "per"
        },
        {
            name: "Pali",
            code: "pi",
            a3_code: "pli"
        },
        {
            name: "Polish",
            code: "pl",
            a3_code: "pol"
        },
        {
            name: "Portuguese",
            code: "pt",
            a3_code: "por"
        },
        {
            name: "Pushto; Pashto",
            code: "ps",
            a3_code: "pus"
        },
        {
            name: "Quechua",
            code: "qu",
            a3_code: "que"
        },
        {
            name: "Romansh",
            code: "rm",
            a3_code: "roh"
        },
        {
            name: "Romanian; Moldavian; Moldovan",
            code: "ro",
            a3_code: "rum"
        },
        {
            name: "Rundi",
            code: "rn",
            a3_code: "run"
        },
        {
            name: "Russian",
            code: "ru",
            a3_code: "rus"
        },
        {
            name: "Sango",
            code: "sg",
            a3_code: "sag"
        },
        {
            name: "Sanskrit",
            code: "sa",
            a3_code: "san"
        },
        {
            name: "Sinhala; Sinhalese",
            code: "si",
            a3_code: "sin"
        },
        {
            name: "Slovak",
            code: "sk",
            a3_code: "slo"
        },
        {
            name: "Slovenian",
            code: "sl",
            a3_code: "slv"
        },
        {
            name: "Northern Sami",
            code: "se",
            a3_code: "sme"
        },
        {
            name: "Samoan",
            code: "sm",
            a3_code: "smo"
        },
        {
            name: "Shona",
            code: "sn",
            a3_code: "sna"
        },
        {
            name: "Sindhi",
            code: "sd",
            a3_code: "snd"
        },
        {
            name: "Somali",
            code: "so",
            a3_code: "som"
        },
        {
            name: "Sotho, Southern",
            code: "st",
            a3_code: "sot"
        },
        {
            name: "Spanish; Castilian",
            code: "es",
            a3_code: "spa"
        },
        {
            name: "Sardinian",
            code: "sc",
            a3_code: "srd"
        },
        {
            name: "Serbian",
            code: "sr",
            a3_code: "srp"
        },
        {
            name: "Swati",
            code: "ss",
            a3_code: "ssw"
        },
        {
            name: "Sundanese",
            code: "su",
            a3_code: "sun"
        },
        {
            name: "Swahili",
            code: "sw",
            a3_code: "swa"
        },
        {
            name: "Swedish",
            code: "sv",
            a3_code: "swe"
        },
        {
            name: "Tahitian",
            code: "ty",
            a3_code: "tah"
        },
        {
            name: "Tamil",
            code: "ta",
            a3_code: "tam"
        },
        {
            name: "Tatar",
            code: "tt",
            a3_code: "tat"
        },
        {
            name: "Telugu",
            code: "te",
            a3_code: "tel"
        },
        {
            name: "Tajik",
            code: "tg",
            a3_code: "tgk"
        },
        {
            name: "Tagalog",
            code: "tl",
            a3_code: "tgl"
        },
        {
            name: "Thai",
            code: "th",
            a3_code: "tha"
        },
        {
            name: "Tibetan",
            code: "bo",
            a3_code: "tib"
        },
        {
            name: "Tigrinya",
            code: "ti",
            a3_code: "tir"
        },
        {
            name: "Tonga (Tonga Islands)",
            code: "to",
            a3_code: "ton"
        },
        {
            name: "Tswana",
            code: "tn",
            a3_code: "tsn"
        },
        {
            name: "Tsonga",
            code: "ts",
            a3_code: "tso"
        },
        {
            name: "Turkmen",
            code: "tk",
            a3_code: "tuk"
        },
        {
            name: "Turkish",
            code: "tr",
            a3_code: "tur"
        },
        {
            name: "Twi",
            code: "tw",
            a3_code: "twi"
        },
        {
            name: "Uighur; Uyghur",
            code: "ug",
            a3_code: "uig"
        },
        {
            name: "Ukrainian",
            code: "uk",
            a3_code: "ukr"
        },
        {
            name: "Urdu",
            code: "ur",
            a3_code: "urd"
        },
        {
            name: "Uzbek",
            code: "uz",
            a3_code: "uzb"
        },
        {
            name: "Venda",
            code: "ve",
            a3_code: "ven"
        },
        {
            name: "Vietnamese",
            code: "vi",
            a3_code: "vie"
        },
        {
            name: "Volapük",
            code: "vo",
            a3_code: "vol"
        },
        {
            name: "Welsh",
            code: "cy",
            a3_code: "wel"
        },
        {
            name: "Walloon",
            code: "wa",
            a3_code: "wln"
        },
        {
            name: "Wolof",
            code: "wo",
            a3_code: "wol"
        },
        {
            name: "Xhosa",
            code: "xh",
            a3_code: "xho"
        },
        {
            name: "Yiddish",
            code: "yi",
            a3_code: "yid"
        },
        {
            name: "Yoruba",
            code: "yo",
            a3_code: "yor"
        },
        {
            name: "Zhuang; Chuang",
            code: "za",
            a3_code: "zha"
        },
        {
            name: "Zulu",
            code: "zu",
            a3_code: "zul"
        }
    ],
    movieGenres: [
        "Action",
        "Adventure",
        "Animated",
        "Biography",
        "Comedy",
        "Crime",
        "Dance",
        "Disaster",
        "Documentary",
        "Drama",
        "Erotic",
        "Family",
        "Fantasy",
        "Found Footage",
        "Historical",
        "Horror",
        "Independent",
        "Legal",
        "Live Action",
        "Martial Arts",
        "Musical",
        "Mystery",
        "Noir",
        "Performance",
        "Political",
        "Romance",
        "Satire",
        "Science Fiction",
        "Short",
        "Silent",
        "Slasher",
        "Sports",
        "Spy",
        "Superhero",
        "Supernatural",
        "Suspense",
        "Teen",
        "Thriller",
        "War",
        "Western"
    ]
}