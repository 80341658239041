import Vue from 'vue'
import dummyData from './dummyData'
import uiDataSets from './uiDataSets'
import appConfig from './appConfig'
import images from './assets/images'
import { Auth } from 'aws-amplify'
import validators from './validators'
import { listShazacinMetadataTitles } from '@/graphql/queries'

Vue.mixin({
  data() {
    return {
      validators,
      allImages: images.all,
      dummyData,
      uiDataSets,
      appConfig,
      isAuthed: false,
      userdata: {
        signedIn: false,
        isAuthed: false,
        username: 'Unknown',
        attributes: {}
      }
    }
  },
  computed: {
    themeImages(){ return images[this.$vuetify.theme.isDark? 'dark': 'light']},
    routes(){
      console.log('this.$vuetify', this.$vuetify);
      return this.$router.options.routes.sort((a, b) => parseFloat(a.meta.listOrder) - parseFloat(b.meta.listOrder));
    },
    route(){
      return this.$route
    },
    routerHistory(){
      console.log('routerHistory',this.$router.history)
      return this.$router.history;
    },
    appMeta() {
      return this.$t('appMeta')
    },
    pageMeta() {
      return this.$t('pageMeta')
    },
    buttonLang(){
      return this.$t('buttons')
    },
    inputLang(){
      return this.$t('inputs')
    }
  },
  methods: {
    pause:(ms) => new Promise(resolve => setTimeout(resolve, ms )),
    deepCompare(comparing, to){
      return this.LD.isEqual(comparing, to);
    },
    testUrlAvailability(url){
      return new Promise(async (resolve) =>{
        const controller = new AbortController();
        const signal = controller.signal;

        try {
          const response = await fetch(url, { signal });
          console.info('testUrlAvailability RESPONSE :: ', response);
          controller.abort();
          resolve(response.status === 200);

        } catch (e) {
          console.error('testUrlAvailability ERROR :: ', e);
          controller.abort();
          resolve(false)
        }
      })
    },
    getAllTitles(){
      return new Promise(async (resolve, reject)=>{
        let self = this;
        const result = await self.$Amplify.API.graphql({ query: listShazacinMetadataTitles, fetchPolicy: 'network-only' })
        const titles = result.data.listShazacinMetadataTitles.items.sort().sort();
        console.info(`yayyyy!! we got titles................`, titles)
        resolve(titles)
      });
    },
    alert(cfg){
      this.$store.dispatch('uiMod/setAlertCfg', cfg);
    },
    confirm(cfg){
      this.$store.dispatch('uiMod/setConfirmModalCfg', cfg);
    },
    showBufferOverlay(cfg){
      this.$store.dispatch('uiMod/setBufferOverlayCfg', cfg);
    },
    hideBufferOverlay(){
      this.$store.dispatch('uiMod/setBufferOverlayVisibility', false);
    },
    toISODate(date){
      let dateObj = typeof date === 'string'?  new Date(date) : typeof date === 'object'? date : false;

      if(!dateObj){
        return date;
      }
      return dateObj.toISOString().substr(0, 10);
    },
    formatDate(date){
      const dateObj = typeof date === 'object'? date : new Date(date);
      return new Intl.DateTimeFormat('en-US').format(date);
    },
    getProgressPercentage(total, fraction){
      return Math.round((fraction/total)*100)
    },
    launchFederatedSignin() {
      Auth.federatedSignIn()
    },
    async checkCredentials(){
      let self = this, user
      console.log('self.$Amplify', self.$Amplify);
      try {
        user = await self.$Amplify.Auth.currentAuthenticatedUser();

        self.isAuthed = true
        self.userdata.isAuthed = true
        self.userdata.username = user && user.attributes && user.attributes.username || 'Unknown'
        self.userdata.attributes = user.attributes
        console.warn('YAYYYYYYYYY mounted... got this user and email:', user.attributes.name, ': ', user.attributes.email)
      } catch (err) {
        self.isAuthed = false
        console.error(`Error occurred during Auth get current user: `, err)
      }
    },
    signOut() {
      let self = this
      self.$Amplify.Auth.signOut()
        .then(data => {
          console.log('SIGNED OUT', data)
          self.userdata.signedIn = false
          self.$router.replace('/Auth')
        })
        .catch(err => console.error('SIGN OUT FAILED', err));
    },
    mounted(){
      console.log('this.$vuetify', this.$vuetify);
    }
  }
})