<template>
  <v-app>
    <confirmation-modal />
    <buffer-overlay />
    <v-app-bar app fixed clipped-left dense color="shaza_dark_blue" dark v-if="isAuthed" flat classf="main_nav">
      <alert />
     
      <v-app-bar-nav-icon app class="hidden-md-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <img :src="allImages.logo_white_wordmark_plain" height="48"/>      
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" @click="signOut">
              <v-icon>mdi-logout-variant</v-icon>
            </v-btn>
          </template>
          <span>{{buttonLang.logout.tooltip}}</span>
        </v-tooltip>
      </v-toolbar-items>
    </v-app-bar>
    <v-navigation-drawer
      class="elevation-1"
      v-if="isAuthed"
      app
      v-model="drawer"
      :expand-on-hover="$vuetify.breakpoint.smAndUp"
      :touchless="$vuetify.breakpoint.smAndUp"
      :bottom="$vuetify.breakpoint.smAndDown"
      :mini-variant.sync="mini"
      clipped
      :permanent="$vuetify.breakpoint.smAndUp"
      :width="400"
      >
      <v-tabs vertical  active-class="nav_active" slider-color="shaza_light_brown" slider-size="8" >
        <template v-for="(item, i) in routes">
          <v-tab :key="`nav_link${i}`" :to="{path: item.path}" v-if="showInNav(item)">
            <v-row class="nav_tab pa-2" align="center">
              <v-icon large class="nav_icon" left>{{item.meta.icon}}</v-icon>
              <span v-if="!mini">{{pageMeta[item.name]? pageMeta[item.name].displayName : 'Loading...'}}</span>
            </v-row>
          </v-tab>
        </template>
      </v-tabs>
    </v-navigation-drawer>
    <v-main>
      <v-container fluid fill-height align-start align-content-start>
        <v-row>
          <v-col>
            <h1 class="title">{{pageMeta[route.name]? pageMeta[route.name].displayName : 'Loading...'}} </h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-fab-transition>
              <router-view />
            </v-fab-transition>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: 'App',
  metaInfo(){
    return {
      title: this.appMeta? this.appMeta.appName : '',
      titleTemplate: this.pageMeta[this.route.name]? `%s | ${ this.pageMeta[this.route.name].displayName}` : 'Loading...'
    }
  },
  data(){
    return {
      drawer: true,
      mini: false,
    }
  },
  computed:{
    
  },
  methods:{
    showInNav(item){
      if(!item.meta.showInNav){
        return false
      }else{
        return item.meta.authRequired? this.isAuthed? true: false: true
      }
      
    }
  },
  mounted(){
    console.log('this.$vuetify', this.$vuetify);
    console.log('ROUTER', this.$router);
    console.log('ROUTE', this.$route);
    console.log('routerHistory', this.routerHistory);
    this.checkCredentials();
  }
};
</script>
